<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 40 40"
	>
		<path
			d="M1323.35478,22.5346001 L1323.27528,22.6372907 C1322.58291,23.611313 1323.27191,25 1324.50266,25 L1329.00201,25 L1329.00201,38.5 C1329.00201,39.3284271 1329.67349,40 1330.5018,40 L1339.5005,40 L1339.64494,39.9931334 C1340.40549,39.9204487 1341.00029,39.2796961 1341.00029,38.5 L1341.00029,30 L1344.99971,30 L1344.99971,38.5 C1344.99971,39.3284271 1345.67119,40 1346.4995,40 L1355.50178,40 L1355.64622,39.9931334 C1356.40676,39.9204487 1357.00156,39.2796961 1357.00156,38.5 L1357.00099,25 L1361.49734,25 C1362.77204,25 1363.46562,23.5103498 1362.64522,22.5346001 L1344.14788,0.534600096 C1343.54856,-0.178200032 1342.45144,-0.178200032 1341.85212,0.534600096 L1323.35478,22.5346001 Z M1343,3.831 L1358.2758,22 L1355.50178,22 L1355.35734,22.0068666 C1354.5968,22.0795513 1354.002,22.7203039 1354.002,23.5 L1354.00142,37 L1347.99928,37 L1347.99928,28.5 C1347.99928,27.6715729 1347.3278,27 1346.4995,27 L1339.5005,27 L1339.35606,27.0068666 C1338.59552,27.0795513 1338.00072,27.7203039 1338.00072,28.5 L1338.00072,37 L1332.00158,37 L1332.00158,23.5 L1331.99472,23.35554 C1331.92204,22.5948881 1331.28138,22 1330.5018,22 L1327.7232,22 L1343,3.831 Z"
			transform="translate(-1323)"
		/>
	</svg>
</template>
