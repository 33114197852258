<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 40 40"
	>
		<path
			d="M511.5,0 C520.612698,0 528,7.38730163 528,16.5 C528,20.5163288 526.565005,24.1974965 524.179756,27.0587627 L534.56066,37.4393398 C535.146447,38.0251263 535.146447,38.9748737 534.56066,39.5606602 C534.011485,40.109835 533.142423,40.1441584 532.553262,39.6636304 L532.43934,39.5606602 L522.058763,29.1797559 C519.197497,31.5650051 515.516329,33 511.5,33 C502.387302,33 495,25.6126984 495,16.5 C495,7.38730163 502.387302,0 511.5,0 Z M511.5,3 C504.044156,3 498,9.04415588 498,16.5 C498,23.9558441 504.044156,30 511.5,30 C518.955844,30 525,23.9558441 525,16.5 C525,9.04415588 518.955844,3 511.5,3 Z"
			transform="translate(-495)"
		/>
	</svg>
</template>
