<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 40 22"
	>
		<path
			d="M119.999999,18.3498221 L102.574707,0.453574975 C101.996782,-0.139969191 101.047119,-0.152631365 100.453575,0.425293218 C99.8600308,1.0032178 99.8473686,1.95288086 100.425293,2.54642502 L118.925293,21.546425 C119.514145,22.1511917 120.485855,22.1511917 121.074707,21.546425 L139.574707,2.54642502 C140.152631,1.95288086 140.139969,1.0032178 139.546425,0.425293218 C138.952881,-0.152631365 138.003218,-0.139969191 137.425293,0.453574975 L119.999999,18.3498221 Z"
			transform="translate(-100)"
		/>
	</svg>
</template>
