// global mixin
// local data still needed for PageHome and ModuleHomeExperience
import Json from "/src/data/data.json";

export default {
	data() {
		return {
			data: Json,
		};
	},
};
