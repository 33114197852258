<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 60 60"
	>
		<path
			d="M30,0 C46.5685433,0 60,13.4314567 60,30 C60,46.5685433 46.5685433,60 30,60 C13.4314567,60 0,46.5685433 0,30 C0,13.4314567 13.4314567,0 30,0 Z M21.2006775,24.8473104 L14.4020214,24.8473104 L14.4020214,45.2744497 L21.2006775,45.2744497 L21.2006775,24.8473104 Z M37.8914228,24.3676416 C34.2817837,24.3676416 32.6656875,26.3503088 31.7633456,27.7411856 L31.7633456,24.8478527 L24.9638749,24.8478527 C25.0534846,26.7646298 24.9638749,45.274992 24.9638749,45.274992 L31.7633456,45.274992 L31.7633456,33.8667921 C31.7633456,33.2562922 31.8074716,32.6472837 31.9872339,32.2101858 C32.4785934,30.9905418 33.5973562,29.7277845 35.4756285,29.7277845 C37.9367708,29.7277845 38.920576,31.6009061 38.920576,34.3459186 L38.920576,45.2744497 L45.7189605,45.2744497 L45.719232,33.5621523 C45.719232,27.2878186 42.3652519,24.3676416 37.8914228,24.3676416 Z M17.84629,15 C15.5205126,15 14,16.5255041 14,18.5283721 C14,20.4897539 15.475572,22.0591846 17.7580381,22.0591846 L17.8014852,22.0591846 C20.1720674,22.0591846 21.6484541,20.4897539 21.6484541,18.5283721 C21.6043281,16.5255041 20.1720674,15 17.84629,15 Z"
		/>
	</svg>
</template>
