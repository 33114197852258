<template>
	<div id="contact">
		<div class="container section">
			<h2 class="align-center">Contact</h2>
			<p class="align-center">
				You can contact me on
				<a
					class="link link-external"
					href="https://www.linkedin.com/in/gaelbvr"
					target="_blank"
					rel="noopener noreferrer"
					>LinkedIn</a
				>, or send me an
				<a
					class="link link-external"
					href="mailto:contact@lebowyer.com"
					target="_blank"
					rel="noopener noreferrer"
					>email</a
				>.
				<!-- or using this <router-link to="/home#home-contact" class="link link-primary">contact form</router-link>. -->
			</p>
			<br />
			<!-- <form id="home-contact-form" class="align-center">
					<input
						id="home-contact-form-email"
						class="field field-icon-email"
						type="email"
						placeholder="Email*"
						required
					/>
					<textarea
						id="home-contact-form-message"
						class="field field-icon-message"
						type="text"
						placeholder="Message*"
						required
					></textarea>
					<button type="submit" class="button button-block button-primary--gradient">Send Message</button>
				</form> -->
		</div>
	</div>
</template>
