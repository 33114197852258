<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 40 40"
	>
		<path
			d="M20,0 C31.045695,-2.02906125e-15 40,8.954305 40,20 C40,31.045695 31.045695,40 20,40 C8.954305,40 1.3527075e-15,31.045695 0,20 C-1.3527075e-15,8.954305 8.954305,2.02906125e-15 20,0 Z M20.6179775,10 C19.2996255,10 18.0561798,10.2268245 16.8876404,10.6804734 C15.7191011,11.1341223 14.7003745,11.7948718 13.8314607,12.6627219 C12.9625468,13.530572 12.2734082,14.5907298 11.7640449,15.8431953 C11.2546816,17.0956607 11,18.5207101 11,20.1183432 C11,21.7357002 11.2446941,23.1607495 11.7340824,24.3934911 C12.2234707,25.6262327 12.897628,26.6568047 13.7565543,27.4852071 C14.6154806,28.3136095 15.619226,28.9398422 16.7677903,29.3639053 C17.9163546,29.7879684 19.1498127,30 20.4681648,30 C21.8464419,30 23.1048689,29.7731755 24.2434457,29.3195266 C25.2555139,28.9162831 26.093972,28.4351182 26.75882,27.8760319 L27,27.6627219 L27,18.7869822 L19.8689139,18.7869822 L19.8689139,22.3372781 L23.1048689,22.3372781 L23.1048689,25.6508876 C22.8651685,25.8481262 22.5555556,25.9960552 22.17603,26.0946746 C21.7965044,26.1932939 21.4069913,26.2426036 21.0074906,26.2426036 C19.1697878,26.2426036 17.7965044,25.6952663 16.8876404,24.6005917 C15.9787765,23.5059172 15.5243446,21.9625247 15.5243446,19.9704142 C15.5243446,19.0039448 15.6491885,18.1360947 15.8988764,17.3668639 C16.1485643,16.5976331 16.5031211,15.9467456 16.9625468,15.4142012 C17.4219725,14.8816568 17.9662921,14.4723866 18.5955056,14.1863905 C19.2247191,13.9003945 19.9188514,13.7573964 20.6779026,13.7573964 C21.5168539,13.7573964 22.2009988,13.8856016 22.7303371,14.1420118 C23.1714524,14.355687 23.5952282,14.6378479 24.0016646,14.9884944 L24.2434457,15.2071006 L26.670412,12.5147929 C26.031211,11.8639053 25.2072409,11.2820513 24.1985019,10.7692308 C23.1897628,10.2564103 21.9962547,10 20.6179775,10 Z"
		/>
	</svg>
</template>
