<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 36 40"
	>
		<path
			d="M320,0 C326.136965,0 331.628341,2.76409569 335.29709,7.11524928 C334.708717,7.03884047 334.109069,7 333.5,7 C326.044156,7 320,12.8202983 320,20 C320,27.1797017 326.044156,33 333.5,33 C334.109069,33 334.708717,32.9611595 335.29642,32.8859104 C331.628341,37.2359043 326.136965,40 320,40 C308.954305,40 300,31.045695 300,20 C300,8.954305 308.954305,0 320,0 Z M327.478861,4.72800965 C325.192662,3.60735449 322.646689,3 320,3 C310.611159,3 303,10.6111593 303,20 C303,29.3888407 310.611159,37 320,37 C322.812139,37 325.510659,36.3143292 327.904594,35.0559289 C321.556462,32.8368228 317,26.9485673 317,20 C317,13.1692601 321.403243,7.36317735 327.583216,5.06011215 L327.904594,4.94412477 L327.478861,4.72800965 Z"
			transform="translate(-300)"
		/>
	</svg>
</template>
