<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 40 40"
	>
		<path
			d="M1277,3 C1278.65685,3 1280,4.34314575 1280,6 L1280,40 C1280,41.6568542 1278.65685,43 1277,43 L1243,43 C1241.34315,43 1240,41.6568542 1240,40 L1240,6 C1240,4.34314575 1241.34315,3 1243,3 L1277,3 Z M1277,15 L1243,15 L1243,40 L1277,40 L1277,15 Z M1277,6 L1243,6 L1243,12 L1277,12 L1277,6 Z M1256,7 C1257.10457,7 1258,7.8954305 1258,9 C1258,10.1045695 1257.10457,11 1256,11 C1254.89543,11 1254,10.1045695 1254,9 C1254,7.8954305 1254.89543,7 1256,7 Z M1251,7 C1252.10457,7 1253,7.8954305 1253,9 C1253,10.1045695 1252.10457,11 1251,11 C1249.89543,11 1249,10.1045695 1249,9 C1249,7.8954305 1249.89543,7 1251,7 Z M1246,7 C1247.10457,7 1248,7.8954305 1248,9 C1248,10.1045695 1247.10457,11 1246,11 C1244.89543,11 1244,10.1045695 1244,9 C1244,7.8954305 1244.89543,7 1246,7 Z"
			transform="translate(-1240 -3)"
		/>
	</svg>
</template>
