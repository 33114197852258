<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 40 40"
	>
		<path
			d="M1412.5,-1.13686838e-13 C1413.2797,-1.13686838e-13 1413.92045,0.594888083 1413.99313,1.35553999 L1414,1.5 L1414,11.135 L1427.83163,37.8095243 C1428.32945,38.7696058 1427.67863,39.9085946 1426.62795,39.9947889 L1426.5,40 L1389.5,40 C1388.41853,40 1387.70697,38.8979374 1388.1141,37.925514 L1388.16837,37.8095243 L1402,11.133 L1402,1.5 C1402,0.720303883 1402.59489,0.0795513218 1403.35554,0.00686657806 L1403.5,-1.13686838e-13 C1404.2797,-1.13686838e-13 1404.92045,0.594888083 1404.99313,1.35553999 L1405,1.5 L1405,11.5 C1405,11.6922584 1404.96304,11.8822652 1404.89162,12.0598117 L1404.83163,12.1904757 L1402.85499,16 L1413.14399,16 L1411.16837,12.1904757 C1411.07987,12.0197975 1411.02521,11.8341067 1411.00689,11.6436121 L1411,11.5 L1411,1.5 C1411,0.671572875 1411.67157,-1.13686838e-13 1412.5,-1.13686838e-13 Z M1414.69899,19 L1401.29999,19 L1391.967,36.999 L1424.032,36.999 L1414.69899,19 Z M1412.5,28 C1413.88071,28 1415,29.1192881 1415,30.5 C1415,31.8807119 1413.88071,33 1412.5,33 C1411.11929,33 1410,31.8807119 1410,30.5 C1410,29.1192881 1411.11929,28 1412.5,28 Z M1404,24 C1406.20914,24 1408,25.790861 1408,28 C1408,30.209139 1406.20914,32 1404,32 C1401.79086,32 1400,30.209139 1400,28 C1400,25.790861 1401.79086,24 1404,24 Z M1411.5,22 C1412.32843,22 1413,22.6715729 1413,23.5 C1413,24.3284271 1412.32843,25 1411.5,25 C1410.67157,25 1410,24.3284271 1410,23.5 C1410,22.6715729 1410.67157,22 1411.5,22 Z"
			transform="translate(-1388)"
		/>
	</svg>
</template>
